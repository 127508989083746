import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Button, CircularProgress } from "@material-ui/core";

import { SelectByDateWithCustomPeriod } from "../../../../components/ui";
import useStyles from "./useStyles";
import { BILLING_API_URLS } from "../../../../api/billing";
import { APIBilling } from "../../../../api";
import { ISalesStatsByDayResponse } from "../../../../api/billing/index.types";
import { buildReport } from "./utils";

const ExportCsvProductsStats: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState<ISalesStatsByDayResponse["data"]["report"]>([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllTime, setIsAllTime] = useState(false);

  const getReport = async (startDate: string, endDate: string, isAllTime?: boolean) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsAllTime(!!isAllTime);
  };

  useQuery({
    enabled: !!startDate || !!endDate,
    queryKey: [BILLING_API_URLS.getSalesStatsByDay, startDate, endDate, isAllTime],
    async queryFn({ signal }) {
      try {
        setIsLoading(true);
        const query = new URLSearchParams();
        query.append("startDate", startDate);
        query.append("endDate", endDate);
        query.append("isAllTime", String(isAllTime));

        const response = await APIBilling.getSalesStatsByDayRequest(query.toString(), signal);
        setReport(response.data.report);
        setIsLoading(false);

        return response;
      } catch (error) {
        if (error.code !== "ERR_CANCELED") {
          setIsLoading(false);
        }
      }
    },
  });

  const downloadReport = () =>
    buildReport(report, {
      startDate,
      endDate,
      isAllTime,
    });

  return (
    <div>
      <h2>Sales by days</h2>

      <div className={classes.datePickerContainer}>
        <SelectByDateWithCustomPeriod
          setIsLoading={setIsLoading}
          getReport={getReport}
          setReport={() => void null}
        />

        <Button
          className={classes.exportButton}
          variant="contained"
          color="primary"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={20} />}
          onClick={downloadReport}
        >
          Export CSV
        </Button>
      </div>
    </div>
  );
};

export default ExportCsvProductsStats;
