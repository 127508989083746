import React from "react";
import cx from "classnames";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { get } from "lodash";
import { uniqueId } from "lodash";

import useStyles from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";
import {
  ContainerWithLoader,
  PaginationWithLimit,
  TableDataCellWithSort,
} from "../../../../components/ui";
import copyToClipboardInXlsFormat, { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { IPaginationState } from "../../../../hooks/usePagination";
import { ISortBy } from "../../../../types";

type IProps<D> = {
  columns: IColum[];
  subColumns?: IColum[];
  title?: React.ReactElement | null;
  loading: boolean;
  data: (D & { sub_table_data?: { id: string; [key: string]: string | number }[] })[];
  extractKey: (data: D) => string | number;
  colSpan?: number;
  theadTitle?: string;
  csv_table_name?: string;
} & (
  | {
      pagination: IPaginationState;
      handleChangePage: (page: number) => void;
    }
  | {
      pagination?: IPaginationState;
      handleChangePage?: (page: number) => void;
    }
) &
  (
    | {
        handleSortBy?: (arg0: string) => void;
        sortBy?: ISortBy;
      }
    | {
        handleSortBy: (arg0: string) => void;
        sortBy: ISortBy;
      }
  );

const Table = <D,>({
  title,
  loading,
  data,
  columns,
  pagination,
  handleChangePage,
  extractKey,
  subColumns,
  colSpan,
  theadTitle,
  csv_table_name,
  sortBy,
  handleSortBy,
}: IProps<D>): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const handleCopyToClipBoard = () =>
    copyToClipboardInXlsFormat({
      data,
      columns,
      sub_columns: subColumns,
      table_name: csv_table_name,
    });

  return (
    <div className={classes.tableContainer}>
      {title && (
        <div>
          <span>{title}</span>
        </div>
      )}

      <ContainerWithLoader isLoading={loading} report={data}>
        <div className={generalClasses.tableHorizontalScroll}>
          <table
            className={cx(
              generalClasses.table,
              generalClasses.smTable,
              generalClasses.tableWithoutHover,
              generalClasses.noTextWrap
            )}
          >
            <thead>
              <tr>
                <td colSpan={colSpan || columns.length}>
                  <IconButton size="small" onClick={handleCopyToClipBoard}>
                    <FileCopyIcon fontSize="inherit" />
                  </IconButton>
                  {theadTitle && <span className={classes.theadTitle}>{theadTitle}</span>}
                </td>
              </tr>
              <tr>
                {columns.map((r) => {
                  if (r.isSortable && sortBy && handleSortBy) {
                    return (
                      <TableDataCellWithSort
                        key={r.by}
                        by={r.by}
                        title={r.title}
                        sortBy={sortBy}
                        newSortBy={handleSortBy}
                      />
                    );
                  }
                  return <td key={r.by}>{r.title}</td>;
                })}
              </tr>
            </thead>

            <tbody>
              {data.map((d) => {
                const { sub_table_data } = d;
                return (
                  <React.Fragment key={extractKey(d)}>
                    <tr
                      className={cx({
                        [classes.borderBottom]: sub_table_data && !sub_table_data.length,
                      })}
                    >
                      {columns.map((r, i) => {
                        // @ts-ignore // TODO fix TS error
                        const cellValue = r.getCellValue
                          ? r.getCellValue(get(d, r.path, ""))
                          : get(d, r.path, "");
                        return (
                          <td
                            key={
                              typeof cellValue === "object" || !cellValue
                                ? i
                                : cellValue + uniqueId()
                            }
                          >
                            {cellValue}
                          </td>
                        );
                      })}
                    </tr>
                    {!!sub_table_data?.length && (
                      <>
                        {
                          <tr className={classes.subTableHeader}>
                            {subColumns &&
                              subColumns.map((sc) => <td key={sc.title}>{sc.title}</td>)}
                          </tr>
                        }
                        {sub_table_data.map((subData) => {
                          const isLastRow = sub_table_data.at(-1)?.id === subData.id;
                          return (
                            <tr
                              key={subData.id}
                              className={cx({ [classes.borderBottom]: !!isLastRow })}
                            >
                              {subColumns &&
                                subColumns.map(({ path, getCellValue }) => {
                                  return (
                                    <td key={path}>
                                      {getCellValue ? getCellValue(subData[path]) : subData[path]}
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </ContainerWithLoader>
      {pagination && handleChangePage && (
        <PaginationWithLimit {...pagination} setPageState={handleChangePage} />
      )}
    </div>
  );
};

export default Table;
