import React from "react";

import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { IntlFormatUSD } from "../../../../utils";
import { CopyToClipboard } from "../../../../components/ui";

export const COLUMNS: Array<IColum> = [
  {
    by: "name",
    title: "Name",
    path: "name",
    getCellValue: (value: unknown): React.ReactElement => {
      const stringValue = value as unknown as string;
      return (
        <>
          {stringValue?.split(", ").map((v) => (
            <CopyToClipboard key={v} value={v} />
          ))}
        </>
      );
    },
  },
  {
    by: "amount",
    title: "Amount",
    path: "amount",
    getCellValue: (value) => <CopyToClipboard value={IntlFormatUSD(value as unknown as number)} />,
  },
];
