import downloadCsv from "../../../../utils/downloadCsv";
import { ISalesStatsByDayResponse } from "../../../../api/billing/index.types";

type TBuildReport = (
  report: ISalesStatsByDayResponse["data"]["report"],
  period: {
    startDate: string;
    endDate: string;
    isAllTime: boolean;
  }
) => void;
export const buildReport: TBuildReport = (report, period) => {
  const data = [
    [
      "Date",
      "Total Net",
      "US Net",
      "Non-us Net",
      "TCP25K QT",
      "TC25K NT",
      "TCP50K QT",
      "TCP50K NT",
      "TCP100K QT",
      "TCP100K NT",
      "GAU MINI 50K QT",
      "GAU MINI 50K NT",
      "GAU MINI 100QT",
      "GAU MINI 100NT",
      "GAU MINI 150K QT",
      "GAU MINI 150K NT",
      "GAU MINI 200K QT",
      "GAU MINI 200K NT",
      "RESETS QT",
      "RESETS NT",
      "FREE RESETS",
    ],
  ];

  report.forEach((row) => {
    const {
      date,
      gau_50_net_not_us,
      gau_100_net_not_us,
      gau_150_net_not_us,
      gau_200_net_not_us,
      tcp_25_net_not_us,
      tcp_50k_net_not_us,
      tcp_100_net_not_us,

      gau_50_net_us,
      gau_100_net_us,
      gau_150_net_us,
      gau_200_net_us,
      tcp_25k_net_us,
      tcp_50k_net_us,
      tcp_100k_net_us,

      resets_net_us,
      resets_net_not_us,

      gau_50_qt,
      gau_100_qt,
      gau_150_qt,
      gau_200_qt,
      tcp_25k_qt,
      tcp_50k_qt,
      tcp_100_net_not_us_qt,

      count_free_resets_total,
      count_reset_total,
    } = row;

    const us_net =
      gau_50_net_us +
      gau_100_net_us +
      gau_150_net_us +
      gau_200_net_us +
      tcp_25k_net_us +
      tcp_50k_net_us +
      tcp_100k_net_us;

    const not_us_net =
      gau_50_net_not_us +
      gau_100_net_not_us +
      gau_150_net_not_us +
      gau_200_net_not_us +
      tcp_25_net_not_us +
      tcp_50k_net_not_us +
      tcp_100_net_not_us;

    const total_net = us_net + not_us_net + resets_net_us + resets_net_not_us;

    data.push([
      date,
      total_net.toString(),
      (us_net + resets_net_us).toString(),
      (not_us_net + resets_net_not_us).toString(),
      tcp_25k_qt.toString(),
      (tcp_25_net_not_us + tcp_25k_net_us).toString(),
      tcp_50k_qt.toString(),
      (tcp_50k_net_not_us + tcp_50k_net_us).toString(),
      tcp_100_net_not_us_qt.toString(),
      (tcp_100_net_not_us + tcp_100k_net_us).toString(),
      gau_50_qt.toString(),
      (gau_50_net_not_us + gau_50_net_us).toString(),
      gau_100_qt.toString(),
      (gau_100_net_not_us + gau_100_net_us).toString(),
      gau_150_qt.toString(),
      (gau_150_net_not_us + gau_150_net_us).toString(),
      gau_200_qt.toString(),
      (gau_200_net_not_us + gau_200_net_us).toString(),
      count_reset_total.toString(),
      (resets_net_us + resets_net_not_us).toString(),
      count_free_resets_total.toString(),
    ]);
  });

  const getFileName = (): string => {
    if (period.isAllTime) return "sales_by_days-all-time";
    return `sales_by_days-${period.startDate}-per-${period.endDate}`;
  };

  downloadCsv(data, getFileName());
};
